/*!
Theme Name: Orange Soccer Academy
Author: Koach
Author URI: https://koachhub.com/
Description: Custom theme developed for Orange Soccer Academy client
*/

@import 'icons/osa-icons';
@import 'common-style.scss';

.theme--osa {
  .loginscreen {
    &__form {
      .form--buttons {
        .btn {
          &:first-of-type {
            background-color: $black !important;
            color: $white;
          }

          &.btn--loader {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
